var anchoventana;
var altoventana;
var md=768;

var swiperInscripcion;
var swiperConferencias;
var swiperRegistroForm;

(function($) {
  var Sage = {
    'common': {
      init: function() {

        /**
         * declaracion variables globales
         */
          anchoventana=$(document).width();
          altoventana=$(document).height();
          md=768;

         /**
          * init plugins y listeners comunes de proyectos
          */
         PLUGINS.init();
         LISTENERS.init();

        /**
         * js del proyecto
         */

          //FAQs relacionadas
          $(".js-faq-relacionada").click(function(e){
            var faqRelacionada = $(this).data("faq-relacionada");
            // console.log(faqRelacionada);
            $(faqRelacionada).find(".collapsed").trigger("click"); 
            PLG_scrollTo(0.5,faqRelacionada,0,100);
          });


          jQuery(document).bind('gform_confirmation_loaded', function(){
           PLG_scrollTo(0.5,".wrap",0,0);
          });



          // carrusel organizaciones
          var swiperLogos=[];

          $(".swiper-container-organizaciones").each(function(index, el) {

            $(this).addClass('swiper-container-organizaciones_'+index);

            swiperLogos.push(new Swiper('.swiper-container-organizaciones_'+index, {
              slidesPerView: 5,
              spaceBetween: 10,
              slidesPerGroup: 5,
              pagination: {
                clickable: true,
              },
              navigation: {
                nextEl: $(this).closest('#section-home-organizaciones').find(".organizaciones-next"),
                prevEl: $(this).closest('#section-home-organizaciones').find(".organizaciones-prev"),
              },
              breakpoints: {
                990: {
                  slidesPerView: 3,
                  spaceBetween: 0,
                  slidesPerGroup: 3,

                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 0,
                  slidesPerGroup: 2,

                },
                480: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                  slidesPerGroup: 1,

                }
              }, 
              autoplay: {
                delay: 10000,
                stopOnLast: true
              },
            }));

          });


          /*
          var swiperLogos = new Swiper('.swiper-container-organizaciones', {
            slidesPerView: 5,
            spaceBetween: 10,
            slidesPerGroup: 5,
            pagination: {
              clickable: true,
            },
            navigation: {
              nextEl: ".organizaciones-next",
              prevEl: ".organizaciones-prev",
            },
            breakpoints: {
              990: {
                slidesPerView: 3,
                spaceBetween: 0,
                slidesPerGroup: 3,

              },
              640: {
                slidesPerView: 2,
                spaceBetween: 0,
                slidesPerGroup: 2,

              },
              480: {
                slidesPerView: 1,
                spaceBetween: 0,
                slidesPerGroup: 1,

              }
            }, 
            autoplay: {
              delay: 10000,
              stopOnLast: true
            },
          });
          */



          // rubrayado menu
          $("header .nav-item").hover(
            function(){
              var menuDestino=$(this).find("a");
              colocarMenuUnderline(menuDestino);
            },
            function(){
              var menuDestino=$(".nav-item.active").find("a");
              colocarMenuUnderline(menuDestino);
              if ($(this).find(".sub-menu").length > 0){
                $(this).find(".sub-menu").slideUp("fast");
              }
            }
          )
          var menuDestino=$(".nav-item.active").find("a");
          colocarMenuUnderline(menuDestino);
          
          // submenu
          $(".nav-item").click(function(){
            if ($(this).find(".sub-menu").length > 0){ 
                $(this).find(".sub-menu").toggle();
                var posleft = $(this).position().left;
                var anchoContenedor=$(this).closest(".navbar-nav").width();
                var anchoMenu=$(this).width();
                console.log(posleft+" // "+anchoContenedor+" // "+anchoMenu);
                console.log(" right: " + anchoContenedor-(posleft+anchoMenu));
                $(".sub-menu").css("right",anchoContenedor-(posleft+anchoMenu));

                if($(".js-lupa").hasClass("activo")){
                  $(".js-lupa").trigger("click");
                }
            }
          });

          //clic lupa
          $(".js-lupa").click(function(){
            if($(this).hasClass("activo")){
              $(this).removeClass("activo");
              TweenMax.fromTo($(".js-buscador"),0.5,
                {
                  height:50,
                  opacity:1
                },
                {
                  height:0,
                  opacity:0,
                  display:"none",
                  ease:Power4.easeOut
                }
              )
            }else{
              $(this).addClass("activo");
              $(".sub-menu").slideUp("fast");

              TweenMax.fromTo($(".js-buscador"),0.5,
                {
                  height:0,
                  opacity:0
                },
                {
                  height:50,
                  opacity:1,
                  display:"block",
                  ease:Power4.easeOut
                }
              )
            }
          });

          //clic galeria footer
          $(".js-foto-galeria").click(function(e){
            e.preventDefault();
            var foto_url=$(this).data("foto-url");
            var foto_index=$(this).data("index");
            console.log("CLICK GALERIA FOOTER");
            console.log(foto_url);
            $("#modalGaleria").attr("foto-activa",foto_index);
            $("#modalGaleria .modal-body").html("<img src='"+foto_url+"' class='img-fluid' alt='Fotografía galería ASLAN' />");
            if(!$("#modalGaleria").hasClass('show')){
              $("#modalGaleria").modal("show");
            }
          });

          $("#modalGaleria #prev-btn").click(function(){
              var foto_activa=parseInt($("#modalGaleria").attr("foto-activa"));
              if(foto_activa==1){
                foto_anterior=10;
              }else{
                foto_anterior=foto_activa-1;
              }
              $(".js-foto-galeria[data-index='"+foto_anterior+"']").trigger("click");
          })
          $("#modalGaleria #next-btn").click(function(){
              var foto_activa=parseInt($("#modalGaleria").attr("foto-activa"));
              if(foto_activa==10){
                foto_siguiente=1;
              }else{
                foto_siguiente=foto_activa+1;
              }
              $(".js-foto-galeria[data-index='"+foto_siguiente+"']").trigger("click");
          })




          //contador
          if ($("#section-contador").length > 0 && $("#section-contador").data("fecha-fin")!= null){
            var fecha=$("#section-contador").data("fecha-fin");
            var countDownDate = new Date(fecha.replace(' ', 'T')).getTime(); //"Apr 11, 2018 09:00:00"
            var x = setInterval(function() {
                var now = new Date().getTime();
                var distance = countDownDate - now;
                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                //console.log("contador > "+countDownDate);
                $("#section-contador .dias p").html(days);
                $("#section-contador .horas p").html(hours);
                $("#section-contador .minutos p").html(minutes);
                $("#section-contador .segundos p").html(seconds);
                if (distance < 0) {
                    clearInterval(x);
                    $("#section-contador .dias p").html("0");
                    $("#section-contador .horas p").html("0");
                    $("#section-contador .minutos p").html("0");
                    $("#section-contador .segundos p").html("0");
                }
            }, 1000);
          }


          //carrusel speakers
          var swiperSpeakers = new Swiper('.carrusel-speakers', {
              slidesPerView: 4,
              //autoplay:4,
              navigation: {
                nextEl: '.carrusel-speakers .swiper-button-next',
                prevEl: '.carrusel-speakers .swiper-button-prev',
              },
              breakpoints: {
                990: {
                  slidesPerView: 3,
                  spaceBetween: 0,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 0,
                },
                320: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                }
              }, 
              autoplay: {
                delay: 5000,
                stopOnLast: true,
              },
          });


          $('.swiper-container-home').each(function(index, element){

                    $(this).addClass('s'+index);
                    $(this).find(".home-next").addClass('next'+index);
                    $(this).find(".home-prev").addClass('prev'+index);
                    $(this).find(".swiper-pagination-home").addClass('pagination'+index);
                    //carrusel home
                    var swiper = new Swiper('.s'+index, {
                      lazy: true,
                      pagination: {
                        el: '.pagination'+index,
                        clickable: true,
                      },
                      navigation: {
                        nextEl: '.next'+index,
                        prevEl: '.prev'+index,
                      },
                      autoplay: {
                        delay: 8000,
                      },
                    });

          });


          //carrusel conferencias agenda
          swiperConferencias = new Swiper('.swiper-container-conferencias-agenda', {
            slidesPerView: 4,
            threshold:10,
            pagination: {
              el: '.pagination-agenda',
              clickable: true,
            },

            navigation: {
              nextEl: '.swiper-container-conferencias-agenda-next',
              prevEl: '.swiper-container-conferencias-agenda-prev',
            },
            breakpoints: {
              990: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              320: {
                slidesPerView: 1,
                spaceBetween: 0,
              }
            },             
          });

          //carrusel conferencias speakers
          var swiperSpeakers = new Swiper('.carrusel-conferencias-speakers', {
              slidesPerView: 5,
              //autoplay:4,
              navigation: {
                nextEl: '.carrusel-conferencias-speakers .swiper-button-next',
                prevEl: '.carrusel-conferencias-speakers .swiper-button-prev',
              },
              breakpoints: {
                990: {
                  slidesPerView: 3,
                  spaceBetween: 0,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                320: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                }
              }, 
              autoplay: {
                delay: 5000,
                stopOnLast: true,
              },
          });          


          //imagen 100% con zoom 
          $('section.img-100pct.zoom').each(function(index, el) {
            var $section = $(el);
            $section.find('.panzoom').panzoom({
                  $zoomIn: $section.find(".zoom-in"),
                  $zoomOut: $section.find(".zoom-out"),
                  $zoomRange: $section.find(".zoom-range"),
                  $reset: $section.find(".reset"),
                  // startTransform: 'scale(1.1)',
                  increment: 0.1,
                  minScale: 1,
                  contain: 'invert'
                });
          });

      },
      finalize: function() {
      }
    },
    'page_template_003_inscripcion': {
      init: function() {

          //carrusel speakers
          swiperInscripcion = new Swiper('#swiper-inscripcion', {
              slidesPerView: 1,
              simulateTouch: false,
              allowTouchMove: false,
              spaceBetween: 100,
              autoHeight:true,
              navigation: {
                nextEl: '#swiper-inscripcion .swiper-button-next',
                prevEl: '#swiper-inscripcion .swiper-button-prev',
              },
          });



      },
      finalize: function() {
      }
    },
    'page_template_015_registro': {
      init: function() {

          //carrusel speakers
          swiperRegistroForm = new Swiper('#swiper-registroForm', {
              slidesPerView: 1,
              simulateTouch: false,
              allowTouchMove: false,
              spaceBetween: 100,
              autoHeight:false,
              navigation: {
                nextEl: '#swiper-registroForm .swiper-button-next',
                prevEl: '#swiper-registroForm .swiper-button-prev',
              },
          });



      },
      finalize: function() {
      }
    },
    'page_template_023_facturacion': {
      init: function() {

          //carrusel speakers
          swiperFacturacion = new Swiper('#swiper-facturacion', {
              slidesPerView: 1,
              simulateTouch: false,
              allowTouchMove: false,
              spaceBetween: 100,
              autoHeight:true,
              navigation: {
                nextEl: '#swiper-facturacion .swiper-button-next',
                prevEl: '#swiper-facturacion .swiper-button-prev',
              },
          });



      },
      finalize: function() {
      }
    },    
  };
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  $(document).ready(UTIL.loadEvents);

  function colocarMenuUnderline(_destino){
    var obj=$(".menu-underline");
    setTimeout(function(){
      TweenMax.to(obj,.5,{x:_destino.position().left,width:_destino.width(), ease:Power4.easeOut});
    },200);
  }

})(jQuery); // Fully reference jQuery after this point.


function resultadoGuardarInscripcion(obj,target, result, spinner_holder){
 
  spinner_holder.fadeOut();
  jQuery('.btn.btn-loader').removeClass("btn-loader");

  if (result['error'] == 'OK'){

    //Pongo los dos campos en el form paso 2 y con la clave aseguro que no se hackea y se actualiza el id correcto de inscripción
    jQuery("#contenedorFormularioInscripcionPaso2 .inscripcionIDI").val(result['idi']);
    jQuery("#contenedorFormularioInscripcionPaso2 .inscripcionClaveI").val(result['clavei']);
    jQuery("#contenedorFormularioInscripcionPaso2 .inscripcionIDU").val(result['idu']);
    jQuery("#contenedorFormularioInscripcionPaso2 .inscripcionEmailU").val(result['emailu']);
    jQuery("#contenedorFormularioInscripcionPaso2 .inscripcionClaveU").val(result['claveu']);

    //pongo los datos en el formulario de validación de código
    jQuery("#contenedorFormularioCodigo .inscripcionIDI").val(result['idi']);
    jQuery("#contenedorFormularioCodigo .inscripcionClaveI").val(result['clavei']);

    //pongo las claves en todos los botones por si se quiere actualizar en el paso correspondiente.
    jQuery(".js-btn-actualizarPlan").attr("data-idInscripcion",result['idi']);
    jQuery(".js-txt-actualizar").html(result['idi']);
    jQuery(".js-txt-actualizar-nombre").html(jQuery("#inscripcionNombre").val());
    // var temp=jQuery(".js-txt-actualizar").attr("href");
    // temp.replace("?idi=","?idi="+result['idi']);
    // temp.replace("?clave=","?clave="+result['idi']);
    var temp=jQuery(".js-txt-actualizar-descarga").attr("href").split("downloadInscripcion.php")[0];
    console.log(temp);
    temp=temp+"downloadInscripcion.php?idi="+result['idi']+"&clave="+result['clavei'];
    console.log(temp);
    jQuery(".js-txt-actualizar-descarga").attr("href",temp);

    if (result['urlWebinar'] && result['urlWebinar']!=""){
      jQuery(".js-txt-actualizar-urlwebinar").attr("href",result['urlWebinar']);
      jQuery(".js-txt-actualizar-urlwebinar").closest('.colboton').removeClass('d-none');
    }

    if (result['urlWebinar2'] && result['urlWebinar2']!=""){
      jQuery(".js-txt-actualizar-urlwebinar-2").attr("href",result['urlWebinar2']);
      jQuery(".js-txt-actualizar-urlwebinar-2").closest('.colboton').removeClass('d-none');
    }

    if (result['urlWebinar3'] && result['urlWebinar3']!=""){
      jQuery(".js-txt-actualizar-urlwebinar-3").attr("href",result['urlWebinar3']);
      jQuery(".js-txt-actualizar-urlwebinar-3").closest('.colboton').removeClass('d-none');
    }

    if (result['urlWebinar4'] && result['urlWebinar4']!=""){
      jQuery(".js-txt-actualizar-urlwebinar-4").attr("href",result['urlWebinar4']);
      jQuery(".js-txt-actualizar-urlwebinar-4").closest('.colboton').removeClass('d-none');
    }

    if (result['urlWebinar5'] && result['urlWebinar5']!=""){
      jQuery(".js-txt-actualizar-urlwebinar-5").attr("href",result['urlWebinar5']);
      jQuery(".js-txt-actualizar-urlwebinar-5").closest('.colboton').removeClass('d-none');
    }                

    var temp=jQuery(".js-txt-actualizar-qr").attr("src").split("qrpase.php")[0];
    console.log(temp);
    temp=temp+"qrpase.php?id="+result['idi']+"&clave="+result['clavei'];
    console.log(temp);
    jQuery(".js-txt-actualizar-qr").attr("src",temp);      

    //pongo la clave que corresponde a cada plan
    jQuery(".js-btn-actualizarPlan[data-tipo='GRATUITO']").attr("data-claveActualizar",result['clavei_GRATUITO']);
    jQuery(".js-btn-actualizarPlan[data-tipo='GRATUITO_PRO']").attr("data-claveActualizar",result['clavei_GRATUITO_PRO']);
    jQuery(".js-btn-actualizarPlan[data-tipo='EXPOSITOR']").attr("data-claveActualizar",result['clavei_EXPOSITOR']);
    jQuery(".js-btn-actualizarPlan[data-tipo='ORO']").attr("data-claveActualizar",result['clavei_ORO']);
    jQuery(".js-btn-actualizarPlan[data-tipo='PLATINO']").attr("data-claveActualizar",result['clavei_PLATINO']);

    //Activo los pases que correspondan
    if (result['tipopase']=="GRATUITO_PRO"){
      jQuery("#paseActual #pase_publico").hide();
      jQuery("#paseActual #pase_profesional").show();
      jQuery("#paseActualizar #pase_profesional").hide();
    }else{
      jQuery("#paseActual #pase_profesional").hide();
      jQuery("#paseActual #pase_publico").show();
    } 

    //pongo el PX
    jQuery("#GAEventInscripcion").attr("src",jQuery("#GAEventInscripcion").attr("data-px"));


    if (result['ocultarpaso2']==true){
      // Si hay que ocultar paso 2, ejecuto el return del paso 2 para que haga lo que le toque hacer y
      // dejo que ese return se encargue de decidir dónde va con los datos de paso 1.
      resultadoGuardarInscripcionPaso2(obj,target, result, spinner_holder);
    }else{
      swiperInscripcion.slideTo(1);
    }
    

    PLG_scrollTo(0.5,"#section-inscripcion",0.5,-130);

    window.lintrk('track', { conversion_id: 4800970 });

    if (typeof window.codigotagmanagerinscripcion !== 'undefined') {
      gtag('event', 'conversion', {'send_to': window.codigotagmanagerinscripcion});
    }    
    

    ga('send', 'event', 'inscription', 'Realizada', jQuery("#slugEvento").val(), jQuery("#slugEvento").val());

    // gtag('event', 'Realizada', {
    //   'event_category' : 'inscription',
    //   'event_label' : jQuery("#slugEvento").val(),
    // });    

    //hago llamada ajax para la generación del pase.
    if (typeof result['urlcreacionpase'] !== 'undefined' && result['urlcreacionpase']!=""){
      $.ajax({
        url: result['urlcreacionpase'],
      }).done(function() {
      });
    }


  }else if(result['error'] == 'REPETIDO'){

    // swiperInscripcion.slideTo(2);
    // PLG_scrollTo(0.5,"#section-inscripcion",0.5,-130);
    if(result['ya_inscrito']==0){
      //no está inscrito, precargo y le dejo en paso 1
      jQuery('#modalAlerta').mostrar_msg("Vale!", "Los datos de su perfil se han precargado, revíselos y continúe para inscribirse.");

    }else{
      jQuery('#modalAlerta').mostrar_msg("Vale!", "Redirigiendo a su página de inscripción.");
      // document.location.href=result['url_redirect'];

    }

  }else{

    jQuery('#modalAlerta').mostrar_msg(result['titulo'], result['mensaje']);

  }

  getRecaptchaValor();

};


function resultadoGuardarInscripcionPaso2(obj,target, result, spinner_holder){
 
  spinner_holder.fadeOut();
  jQuery('.btn.btn-loader').removeClass("btn-loader");

  if (result['error'] == 'OK'){

    //Pongo los dos campos en el form paso 3 y con la clave aseguro que no se hackea y se actualiza el id correcto de inscripción
    jQuery("#contenedorFormularioInscripcionPaso3 .inscripcionIDI").val(result['idi']);
    jQuery("#contenedorFormularioInscripcionPaso3 .inscripcionClaveI").val(result['clavei']);
    jQuery("#contenedorFormularioInscripcionPaso3 .inscripcionIDU").val(result['idu']);
    jQuery("#contenedorFormularioInscripcionPaso3 .inscripcionClaveU").val(result['claveu']);

    //pongo los datos en el formulario de validación de código
    jQuery("#contenedorFormularioCodigo .inscripcionIDI").val(result['idi']);
    jQuery("#contenedorFormularioCodigo .inscripcionClaveI").val(result['clavei']);      

    //pongo la clave que corresponde a cada plan
    jQuery(".js-btn-actualizarPlan[data-tipo='GRATUITO']").attr("data-claveActualizar",result['clavei_GRATUITO']);
    jQuery(".js-btn-actualizarPlan[data-tipo='GRATUITO_PRO']").attr("data-claveActualizar",result['clavei_GRATUITO_PRO']);
    jQuery(".js-btn-actualizarPlan[data-tipo='EXPOSITOR']").attr("data-claveActualizar",result['clavei_EXPOSITOR']);
    jQuery(".js-btn-actualizarPlan[data-tipo='ORO']").attr("data-claveActualizar",result['clavei_ORO']);
    jQuery(".js-btn-actualizarPlan[data-tipo='PLATINO']").attr("data-claveActualizar",result['clavei_PLATINO']);

    //Activo los pases que correspondan
    if (result['tipopase']=="GRATUITO_PRO"){
      jQuery("#paseActual #pase_publico").hide();
      jQuery("#paseActual #pase_profesional").show();
      jQuery("#paseActualizar #pase_profesional").hide();
    }else{
      jQuery("#paseActual #pase_profesional").hide();
      jQuery("#paseActual #pase_publico").show();
    }

    if (result['ocultarpaso2']==true){
      swiperInscripcion.slideTo(1);
    }else{
      swiperInscripcion.slideTo(2);
    }

    PLG_scrollTo(0.5,"#section-inscripcion",0.5,-130);

  }else{

    jQuery('#modalAlerta').mostrar_msg(result['titulo'], result['mensaje']);

  }


};


function resultadoValidarCodigo(obj,target, result, spinner_holder){
  spinner_holder.fadeOut();
  jQuery('.btn.btn-loader').removeClass("btn-loader");

  if (result['error'] == 'OK'){

    console.log("OK");

    tipo=result['tipo'];

    objetoCambiar=jQuery("#paseActualizar .js-"+tipo);

    objetoCambiar.find(".importeAnterior").addClass('tachado');

    if (result['importe']==0){
      $importe="GRATIS";
    }else{
      $importe=result['importe']+"€ + IVA";
    }

    objetoCambiar.find(".importeNuevo").html($importe);
    objetoCambiar.find(".importeNuevo").show();

    objetoCambiar.find(".js-btn-actualizarPlan").attr("data-codigo",result['codigo']);
    objetoCambiar.find(".js-btn-actualizarPlan").attr("data-idcodigo",result['idCodigo']);
    objetoCambiar.find(".js-btn-actualizarPlan").attr("data-claveActualizar",result['clave']);
    objetoCambiar.find(".js-btn-actualizarPlan").attr("data-importe",result['importe']);

    objetoCambiar.find(".js-btn-actualizarPlan").attr("data-desactivado","false");

    

    //JB 270524 se auto clicka para que actualice.
    //if (result['importe']==0){//dejo esto por si lo quieren hacer únicamente con importe = 0
    
      objetoCambiar.find(".js-btn-actualizarPlan").trigger("click");
    /*
    //dejo esto por si lo quieren hacer únicamente con importe = 0
    }
    else{
      jQuery('#modalAlerta').mostrar_msg(result['titulo'], result['mensaje']);
    }
    */
    //fin JB 27052024    

  }else{

    jQuery('#modalAlerta').mostrar_msg(result['titulo'], result['mensaje']);

  }


};


function aslan_login(_txtpersonalizado,_tit,_txt){

  if (usuarioLogado==false){
    setCookie("url_referer_login", document.URL, 365);

    if (_txtpersonalizado==true){

      jQuery("#modalLogin .modal-title").html(_tit);
      jQuery("#modalLogin .modal-body .textoLogin").html(_txt);

    }else{

      jQuery("#modalLogin .modal-title").html(jQuery("#modalLogin .modal-title-original").html());
      jQuery("#modalLogin .modal-body .textoLogin").html("");

    }

    jQuery("#modalLogin").modal("show");

  }
  

}


function resultadoLogin(obj,target, result, spinner_holder){
 
  spinner_holder.fadeOut();

  //console.log(result);
  setCookie("url_referer_login", '', 365);
  if (result['error'] == 'OK'){
    if (result['redirect-url'] != ''){
      document.location.href=result['redirect-url'];
    }
    else{
      document.location.reload();
    }
    


  }else{

    jQuery('#modalAlerta').mostrar_msg(result['titulo'], result['mensaje']);
    jQuery("#modalLogin").find(".btn-loader").removeClass('btn-loader');

  }

};


function aslan_recordar(){

  if(jQuery("#modalLogin").hasClass('show')){

    jQuery("#modalLogin").modal("hide");

  }

  jQuery("#modalRecordarpass").modal("show");

}

function resultadoRecordarpass(obj,target, result, spinner_holder){
 
  spinner_holder.fadeOut();
  jQuery('.btn.btn-loader').removeClass("btn-loader");
  console.log("sii");

  if (result['error'] == 'OK'){

    jQuery('#modalRecordarpass').modal("hide");
    jQuery('#modalAlerta').mostrar_msg(result['titulo'], result['mensaje']);

  }else{

    jQuery('#modalAlerta').mostrar_msg(result['titulo'], result['mensaje']);

  }

};

function resultadoCambiarpass(obj,target, result, spinner_holder){
 
  spinner_holder.fadeOut();
  jQuery('.btn.btn-loader').removeClass("btn-loader");

  if (result['error'] == 'OK'){

    jQuery('#modalCambiarPass').modal("hide");
    jQuery('#modalAlerta').mostrar_msg(result['titulo'], result['mensaje']);

  }else{

    jQuery('#modalAlerta').mostrar_msg(result['titulo'], result['mensaje']);

  }

};

function eliminarVotacionConcurso(_concurso,_fase,_candidatura,_id,_tipo,_clave){

  jQuery.ajax({
    method: "POST",
    url: global.ajax_url,
    data: { action: "eliminarVotacionConcurso", concurso:_concurso, fase:_fase, candidatura:_candidatura, id:_id, tipo:_tipo , clave:_clave},
    dataType: "json",
  })
    .done(function( data ) {

      sumaCambiar1=data.datos.votosTipo1;
      mediaCambiar1=data.datos.mediaTipo1;
      sumaCambiar2=data.datos.votosTipo2;
      mediaCambiar2=data.datos.mediaTipo1;
      /*
      console.log("ID:"+data.datos.ID);
      console.log(sumaCambiar);
      console.log("totales: "+data.datos.votosTotal);
      console.log(jQuery("#candidatura"+data.datos.ID));
      */

      //PARCIALES FASE 1
      jQuery("#candidatura"+data.datos.ID).find(".estrellasParcialesActualizar1").find("i").removeClass('fa-star fa-star-o fa-star-half-o');
      //y ahora pongo lo que corresponda
      for (i=0;i<5;i++){
        if (mediaCambiar1>=i+1){
          jQuery("#candidatura"+data.datos.ID).find(".estrellasParcialesActualizar1").find("i").eq(i).addClass('fa-star')
        }else{
          if (mediaCambiar1>=i+0.5){
            jQuery("#candidatura"+data.datos.ID).find(".estrellasParcialesActualizar1").find("i").eq(i).addClass('fa-star-half-o')
          }else{
            jQuery("#candidatura"+data.datos.ID).find(".estrellasParcialesActualizar1").find("i").eq(i).addClass('fa-star-o')
          }
        }
      }
      jQuery("#candidatura"+data.datos.ID).find(".votosParcialesActualizar1").html(sumaCambiar1);


      //PARCIALES FASE 2 (SOLO SI ESTAMOS EN FASE 2)
      console.log(data.datos);
      if (parseInt(data.datos.tipovoto,10)>1){
        jQuery("#candidatura"+data.datos.ID).find(".estrellasParcialesActualizar2").find("i").removeClass('fa-star fa-star-o fa-star-half-o');
        //y ahora pongo lo que corresponda
        for (i=0;i<5;i++){
          if (mediaCambiar2>=i+1){
            jQuery("#candidatura"+data.datos.ID).find(".estrellasParcialesActualizar2").find("i").eq(i).addClass('fa-star')
          }else{
            if (mediaCambiar2>=i+0.5){
              jQuery("#candidatura"+data.datos.ID).find(".estrellasParcialesActualizar2").find("i").eq(i).addClass('fa-star-half-o')
            }else{
              jQuery("#candidatura"+data.datos.ID).find(".estrellasParcialesActualizar2").find("i").eq(i).addClass('fa-star-o')
            }
          }
        }
        jQuery("#candidatura"+data.datos.ID).find(".votosParcialesActualizar2").html(sumaCambiar2); 
      }          


      //TOTALES
      jQuery("#candidatura"+data.datos.ID).find(".estrellasTotalesActualizar").find("i").removeClass('fa-star fa-star-o fa-star-half-o');
      //y ahora pongo lo que corresponda
      for (i=0;i<5;i++){
        if (data.datos.mediaTotal>=i+1){
          jQuery("#candidatura"+data.datos.ID).find(".estrellasTotalesActualizar").find("i").eq(i).addClass('fa-star')
        }else{
          if (data.datos.mediaTotal>=i+0.5){
            jQuery("#candidatura"+data.datos.ID).find(".estrellasTotalesActualizar").find("i").eq(i).addClass('fa-star-half-o')
          }else{
            jQuery("#candidatura"+data.datos.ID).find(".estrellasTotalesActualizar").find("i").eq(i).addClass('fa-star-o')
          }
        }
      }              
      jQuery("#candidatura"+data.datos.ID).find(".votosTotalesActualizar").html(data.datos.votosTotal);


      //CAMBIO DE ACCIÓN

      cadenaAccion="<strong>Vote aquí</strong>";
      cadenaAccion+='<div class="estrellasVotar text-center">';
      cadenaAccion+='<a href="javascript:;" class="fa fa-star " onclick="votarCandidaturaConcurso(this)"  data-concurso="'+data.datos.concurso+'" data-candidatura="'+data.datos.ID+'" data-fase="'+data.datos.fase+'" data-usuario="'+data.datos.id_usuario+'" data-tipo="'+data.datos.tipovoto+'" data-valor="5" data-clave="'+data.datos.clave5+'"></a>';
      cadenaAccion+='<a href="javascript:;" class="fa fa-star " onclick="votarCandidaturaConcurso(this)"  data-concurso="'+data.datos.concurso+'" data-candidatura="'+data.datos.ID+'" data-fase="'+data.datos.fase+'" data-usuario="'+data.datos.id_usuario+'" data-tipo="'+data.datos.tipovoto+'" data-valor="4" data-clave="'+data.datos.clave4+'"></a>';
      cadenaAccion+='<a href="javascript:;" class="fa fa-star " onclick="votarCandidaturaConcurso(this)"  data-concurso="'+data.datos.concurso+'" data-candidatura="'+data.datos.ID+'" data-fase="'+data.datos.fase+'" data-usuario="'+data.datos.id_usuario+'" data-tipo="'+data.datos.tipovoto+'" data-valor="3" data-clave="'+data.datos.clave3+'"></a>';
      cadenaAccion+='<a href="javascript:;" class="fa fa-star " onclick="votarCandidaturaConcurso(this)"  data-concurso="'+data.datos.concurso+'" data-candidatura="'+data.datos.ID+'" data-fase="'+data.datos.fase+'" data-usuario="'+data.datos.id_usuario+'" data-tipo="'+data.datos.tipovoto+'" data-valor="2" data-clave="'+data.datos.clave2+'"></a>';
      cadenaAccion+='<a href="javascript:;" class="fa fa-star " onclick="votarCandidaturaConcurso(this)"  data-concurso="'+data.datos.concurso+'" data-candidatura="'+data.datos.ID+'" data-fase="'+data.datos.fase+'" data-usuario="'+data.datos.id_usuario+'" data-tipo="'+data.datos.tipovoto+'" data-valor="1" data-clave="'+data.datos.clave1+'"></a>';
      cadenaAccion+='</div>';

      jQuery("#candidatura"+data.datos.ID).find(".contenedorAccion").html(cadenaAccion);

      jQuery('#modalAlerta').mostrar_msg(data.titulo,data.mensaje);
    

  });

}


function votarCandidaturaConcurso(_domObj){

  obj=jQuery(_domObj);

  datos = { 
    action:'votarCandidatura', 
    concurso:obj.attr("data-concurso"),
    candidatura:obj.attr("data-candidatura"), 
    fase:obj.attr("data-fase"),
    usuario:obj.attr("data-usuario"),
    tipo:obj.attr("data-tipo"),
    valor: obj.attr("data-valor"),
    clave: obj.attr("data-clave")
  };

  jQuery.ajax({
    url: global.ajax_url,
    type: 'POST',
    data: datos,
    dataType: "json"
  })
  .done(function(data) {

    if (data.error=="OK"){
      //console.log("success");

      sumaCambiar1=data.datos.votosTipo1;
      mediaCambiar1=data.datos.mediaTipo1;
      sumaCambiar2=data.datos.votosTipo2;
      mediaCambiar2=data.datos.mediaTipo2;
      /*
      console.log("ID:"+data.datos.ID);
      console.log(sumaCambiar);
      console.log("totales: "+data.datos.votosTotal);
      console.log(jQuery("#candidatura"+data.datos.ID));
      */

      //PARCIALES FASE 1
      jQuery("#candidatura"+data.datos.ID).find(".estrellasParcialesActualizar1").find("i").removeClass('fa-star fa-star-o fa-star-half-o');
      //y ahora pongo lo que corresponda
      for (i=0;i<5;i++){
        if (mediaCambiar1>=i+1){
          jQuery("#candidatura"+data.datos.ID).find(".estrellasParcialesActualizar1").find("i").eq(i).addClass('fa-star')
        }else{
          if (mediaCambiar1>=i+0.5){
            jQuery("#candidatura"+data.datos.ID).find(".estrellasParcialesActualizar1").find("i").eq(i).addClass('fa-star-half-o')
          }else{
            jQuery("#candidatura"+data.datos.ID).find(".estrellasParcialesActualizar1").find("i").eq(i).addClass('fa-star-o')
          }
        }
      }
      jQuery("#candidatura"+data.datos.ID).find(".votosParcialesActualizar1").html(sumaCambiar1);


      //PARCIALES FASE 2 (SOLO SI ESTAMOS EN FASE 2)
      if (parseInt(data.datos.tipovoto,10)>1){
        jQuery("#candidatura"+data.datos.ID).find(".estrellasParcialesActualizar2").find("i").removeClass('fa-star fa-star-o fa-star-half-o');
        //y ahora pongo lo que corresponda
        for (i=0;i<5;i++){
          if (mediaCambiar2>=i+1){
            jQuery("#candidatura"+data.datos.ID).find(".estrellasParcialesActualizar2").find("i").eq(i).addClass('fa-star')
          }else{
            if (mediaCambiar2>=i+0.5){
              jQuery("#candidatura"+data.datos.ID).find(".estrellasParcialesActualizar2").find("i").eq(i).addClass('fa-star-half-o')
            }else{
              jQuery("#candidatura"+data.datos.ID).find(".estrellasParcialesActualizar2").find("i").eq(i).addClass('fa-star-o')
            }
          }
        }
        jQuery("#candidatura"+data.datos.ID).find(".votosParcialesActualizar2").html(sumaCambiar2); 
      }          


      //TOTALES
      jQuery("#candidatura"+data.datos.ID).find(".estrellasTotalesActualizar").find("i").removeClass('fa-star fa-star-o fa-star-half-o');
      //y ahora pongo lo que corresponda
      for (i=0;i<5;i++){
        if (data.datos.mediaTotal>=i+1){
          jQuery("#candidatura"+data.datos.ID).find(".estrellasTotalesActualizar").find("i").eq(i).addClass('fa-star')
        }else{
          if (data.datos.mediaTotal>=i+0.5){
            jQuery("#candidatura"+data.datos.ID).find(".estrellasTotalesActualizar").find("i").eq(i).addClass('fa-star-half-o')
          }else{
            jQuery("#candidatura"+data.datos.ID).find(".estrellasTotalesActualizar").find("i").eq(i).addClass('fa-star-o')
          }
        }
      }              
      jQuery("#candidatura"+data.datos.ID).find(".votosTotalesActualizar").html(data.datos.votosTotal);


      //CAMBIO DE ACCIÓN
      jQuery("#candidatura"+data.datos.ID).find(".contenedorAccion").html('<i class="fa fa-check" aria-hidden="true"></i> Su valoración es de: '+data.datos.valoracion+'<a href="javascript:;" onclick="eliminarVotacionConcurso(\''+data.datos.concurso+'\',\''+data.datos.fase+'\',\''+data.datos.candidatura+'\',\''+data.datos.id_borrado+'\',\''+data.datos.tipovoto+'\',\''+data.datos.clave_borrado+'\')" class="eliminarVotacion"><i class="fa fa-times" aria-hidden="true"></i> Eliminar</a>');


       jQuery('#modalAlerta').mostrar_msg(data.titulo,data.mensaje);

    }else{
      console.log("error controlado");
      jQuery('#modalAlerta').mostrar_msg(data.titulo,data.mensaje);
    }
  })
  .fail(function() {
    console.log("error");
  });

}


function resultadoGuardarPerfil(obj,target, result, spinner_holder){
  spinner_holder.fadeOut();
  jQuery('.btn.btn-loader').removeClass("btn-loader");
  jQuery('#modalAlerta').mostrar_msg(result['titulo'], result['mensaje']);
};

function resultadoGuardarPerfilOrganizacion(obj,target, result, spinner_holder){
  spinner_holder.fadeOut();
  jQuery('.btn.btn-loader').removeClass("btn-loader");
  jQuery('#modalAlerta').mostrar_msg(result['titulo'], result['mensaje']);
};

function resultadoActualizarPolitica(obj,target, result, spinner_holder){
  spinner_holder.fadeOut();
  jQuery('.btn.btn-loader').removeClass("btn-loader");
  jQuery("#modalLegales").modal("hide");
};

function resultadoCambioEmail(obj,target, result, spinner_holder){
  spinner_holder.fadeOut();
  jQuery("#modalCambioEmail").modal("hide");
  jQuery('.btn.btn-loader').removeClass("btn-loader");
  jQuery('#modalAlerta').mostrar_msg(result['titulo'], result['mensaje']);
  console.log(result);
  if (result.error=="OK" && result.refrescar==true){
    console.log("refresco");
    setTimeout(function(){
      location.reload();
    },3000);
    
  }
};



/* DEL REGISTRO */

function resultadoGuardarRegistroForm(obj,target, result, spinner_holder){
 
  spinner_holder.fadeOut();
  jQuery('.btn.btn-loader').removeClass("btn-loader");

  if (result['error'] == 'OK'){

    //Pongo los dos campos en el form paso 2 y con la clave aseguro que no se hackea y se actualiza el id correcto de inscripción
    jQuery("#contenedorFormularioRegistroFormPaso2 .registroFormIDU").val(result['idu']);
    jQuery("#contenedorFormularioRegistroFormPaso2 .registroFormEmailU").val(result['emailu']);
    jQuery("#contenedorFormularioRegistroFormPaso2 .registroFormClaveU").val(result['claveu']);

    swiperRegistroForm.slideTo(1);
    PLG_scrollTo(0.5,"#section-registroForm",0.5,-130);

    window.lintrk('track', { conversion_id: 4800962 });
    ga('send', 'event', 'Registro', 'Realizada', '', '');

  }else{

    jQuery('#modalAlerta').mostrar_msg(result['titulo'], result['mensaje']);

  }

};


function resultadoGuardarRegistroFormPaso2(obj,target, result, spinner_holder){
 
  spinner_holder.fadeOut();
  jQuery('.btn.btn-loader').removeClass("btn-loader");

  if (result['error'] == 'OK'){

    swiperRegistroForm.slideTo(2);
    
    PLG_scrollTo(0.5,"#section-registroForm",0.5,-130);

  }else{

    jQuery('#modalAlerta').mostrar_msg(result['titulo'], result['mensaje']);

  }


};


function resultadoGuardarOptinEmpresas(obj,target, result, spinner_holder){
 
  spinner_holder.fadeOut();
  jQuery('.btn.btn-loader').removeClass("btn-loader");

  if (result['error'] == 'OK'){

    jQuery('#modalAlerta').mostrar_msg(result['titulo'], result['mensaje']);

  }else{

    jQuery('#modalAlerta').mostrar_msg(result['titulo'], result['mensaje']);

  }


};

function resultado_mensajeria(obj,target, result, spinner_holder){
 
  spinner_holder.fadeOut();
  jQuery('.btn.btn-loader').removeClass("btn-loader");

  if (result['error'] == 'OK'){

    jQuery("#modalMensajeria").modal("hide");
    jQuery('#modalAlerta').mostrar_msg(result['titulo'], result['mensaje']);

      jQuery('#modalAlerta [data-toggle="popover"]').popover({ trigger: "manual" , html: true, animation:true})
        .on("mouseenter", function () {
            var _this = this;
            jQuery(this).popover("show");
            if (!jQuery(_this).hasClass('js-tip-faq')){
              jQuery(".popover").on("mouseleave", function () {
                  jQuery(_this).popover('hide');
              });
            }else{
              jQuery(".js-closePopover").click(function(event) {
                jQuery(".popover").popover("hide");
              });              
            }
        }).on("mouseleave", function () {
            var _this = this;
            if (!jQuery(_this).hasClass('js-tip-faq')){
              setTimeout(function () {
                  if (!jQuery(".popover:hover").length) {
                      jQuery(_this).popover("hide");
                  }
              }, 300);
            }
        });     

  }else{

    jQuery('#modalAlerta').mostrar_msg(result['titulo'], result['mensaje']);

  }


};


